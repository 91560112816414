import { Button, Container, Typography, makeStyles } from '@material-ui/core';
import { useCallback, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_ENTRY, EDIT_ENTRIES } from '../gql';
import { EDIT_MARGIN } from '../../Quote/gql';
import { QuoteContext, QuoteDispatchContext } from '../context';
import {
    generateNewEntriesVariables,
    generateEditEntriesVariables,
} from '../utils';
import { QUOTE_ACTION } from '../constants';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    container: {
        padding: '10px',
    },
    error: {
        color: 'red',
    },
}));

const newEntry = {
    trade: '',
    description: '',
    uom: '',
    quantity: '',
    rate: '',
    total: '',
    contract_ref: '',
    isSelected: true,
    isNew: true,
};

const BUTTON_TEXT_OPTION = {
    addLineItem: 'ADD LINE ITEM',
    saveLineItem: 'SAVE LINE ITEM',
    showEntries: 'SHOW ENTRIES',
    showMargin: 'SHOW MARGIN',
};

const ButtonContainer = ({
    refetch,
    savedQuote,
    showingMargin,
    toggleMarginView,
}: any) => {
    const classes = useStyles();
    const { push } = useHistory();

    const [error, setError] = useState('');
    const [buttonText, setButtonText] = useState(
        BUTTON_TEXT_OPTION.addLineItem
    );

    const quote: any = useContext(QuoteContext);
    const dispatch: any = useContext(QuoteDispatchContext);

    const [addEntry, { loading: adding }] = useMutation(ADD_ENTRY, {
        onCompleted: () => {
            refetch();
        },
        onError: () => {
            setError(
                'Failed to save new line item, please contact developer and refresh the page'
            );
        },
    });

    const [editEntries, { loading: saving }] = useMutation(EDIT_ENTRIES, {
        onCompleted: () => {
            refetch();
        },
        onError: () => {
            setError(
                'Failed to save, please contact developer and refresh the page'
            );
        },
    });

    const [editMargin, { loading: savingMargin }] = useMutation(EDIT_MARGIN, {
        onCompleted: () => {
            refetch();
        },
        onError: () => {
            setError(
                'Failed to save margin, please contact developer and refresh the page'
            );
        },
    });

    const handleAddLineItemClick = () => {
        if (buttonText === BUTTON_TEXT_OPTION.addLineItem) {
            dispatch({
                type: QUOTE_ACTION.ADD_ENTRY,
                payload: { newEntry },
            });
            setButtonText(BUTTON_TEXT_OPTION.saveLineItem);
        } else {
            addEntry({
                variables: {
                    quote_id: quote.id,
                    entry_input: generateNewEntriesVariables(quote),
                },
            });
            setButtonText(BUTTON_TEXT_OPTION.addLineItem);
        }
    };

    const handleSaveClick = useCallback(() => {
        if (!quote) return;

        if (showingMargin && quote.marginEdited) {
            editMargin({
                variables: {
                    quote_id: quote.id,
                    quote_margin: quote.margin,
                    quote_margin_span: quote.margin_span,
                },
            });
        } else {
            editEntries({
                variables: {
                    quote_id: quote.id,
                    entries_input: generateEditEntriesVariables(quote),
                },
            });
        }
    }, [showingMargin, editEntries, editMargin, quote]);

    const handleReturnToQuoteClick = useCallback(() => {
        if (!quote) return;
        push(`/quote/${quote.id}`);
    }, [push, quote]);

    const handleResetClick = useCallback(() => {
        if (!savedQuote) return;
        dispatch({
            type: QUOTE_ACTION.SET_QUOTE,
            payload: savedQuote,
        });
    }, [dispatch, savedQuote]);

    return (
        <Container className={classes.container}>
            <Button onClick={handleReturnToQuoteClick}>RETURN TO QUOTE</Button>
            <Button disabled={showingMargin} onClick={handleAddLineItemClick}>
                {buttonText}
            </Button>
            <Button onClick={toggleMarginView}>
                {showingMargin
                    ? BUTTON_TEXT_OPTION.showEntries
                    : BUTTON_TEXT_OPTION.showMargin}
            </Button>
            {buttonText !== BUTTON_TEXT_OPTION.saveLineItem && (
                <Button onClick={handleSaveClick}>SAVE</Button>
            )}
            <Button onClick={handleResetClick}>RESET</Button>
            {saving && <Typography>Saving...</Typography>}
            {adding && <Typography>Adding new line item...</Typography>}
            {savingMargin && (
                <Typography>Saving margin percentage...</Typography>
            )}
            {error && (
                <Typography className={classes.error}>{error}</Typography>
            )}
        </Container>
    );
};

export default ButtonContainer;
