import { convertToCents } from '../../../utils';

const generateQuoteInputVariables = (quote) => {
    const {
        id,
        address_component: {
            unit_number,
            street_number,
            street,
            suburb,
            postcode,
            state,
        },
        quoted_by_id,
        quoted_at,
        quote_ref_id,
        claim_type,
        claim_number,
        company_id,
        margin,
        format_id,
        status,
    } = quote;

    const variables = {
        id,
        address_component: {
            unit_number,
            street_number,
            street,
            suburb,
            postcode,
            state,
        },
        quoted_by_id,
        quoted_at,
        quote_ref_id,
        claim_type,
        claim_number,
        company_id,
        margin,
        format_id,
        status,

        locations: generateUpdateLocationDetails(quote.locations),
    };
    return variables;
};

const generateUpdateLocationDetails = (locations) => {
    return locations.map((location) => {
        return {
            id: location.id,
            entries: generateUpdateEntryDetails(location.entries),
        };
    });
};

const generateUpdateEntryDetails = (entries) => {
    return entries.map((entry) => {
        const entryDetails = {
            id: entry.id,
            trade: entry.trade,
            description: entry.description,
            comment: entry.comment,
            uom: entry.uom,
            quantity: entry.quantity,
            rate_cents: convertToCents(entry.rate_dollars),
            total_cents: convertToCents(entry.rate_dollars * entry.quantity),
            deleted_at: entry.deleted_at,
        };
        let analysedEntryDetails = {
            analysed_entry: null,
        };
        if (!!entry.analysed_entry) {
            analysedEntryDetails = {
                analysed_entry: {
                    id: entry.analysed_entry.id,
                    rate_id: entry.analysed_entry.rate_id,
                    rate_cents: entry.analysed_entry.rate
                        ? convertToCents(entry.analysed_entry.rate.rate_dollars)
                        : null,
                    total_cents: convertToCents(
                        entry.analysed_entry.total_dollars
                    ),
                    total_cents_difference: convertToCents(
                        entry.total_dollars - entry.analysed_entry.total_dollars
                    ),
                },
            };
        }

        return {
            ...entryDetails,
            ...analysedEntryDetails,
        };
    });
};

export { generateQuoteInputVariables };
