import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Container, Table, Divider, TableBody } from '@material-ui/core';

import LocationHeader from './Header';
import LocationSummary from './LocationSummary';
import EntryHeader from '../Entry/EntryHeader';
import EntryRow from '../Entry/EntryRow';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
        width: '80vw',
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const Location = ({ location, removeRoom, updateRoom, handleSave }) => {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <LocationHeader
                location={location}
                removeRoom={removeRoom}
                updateRoom={updateRoom}
            />
            <Divider variant="middle" />
            <Container>
                <Table>
                    <EntryHeader />
                    <TableBody>
                        {location.entries
                            .filter((entry) => entry.deleted_at === null)
                            .map((entry, index) => {
                                return (
                                    <EntryRow
                                        entry={entry}
                                        originalEntry={location.entries[index]}
                                        handleSave={handleSave}
                                        key={`entry-key-${entry.id}`}
                                    />
                                );
                            })}
                        <LocationSummary location={location} />
                    </TableBody>
                </Table>
            </Container>
        </Container>
    );
};

export default Location;
