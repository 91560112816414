import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import {
    Container,
    Box,
    Typography,
    FormGroup,
    Button,
    FormControl,
    TextField,
    Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SUBMIT_SET_PASSWORD = gql`
    mutation setPassword($email: String!, $password: String!, $code: String!) {
        setPassword(email: $email, password: $password, code: $code)
    }
`;

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '3rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        maxWidth: '6rem',
        margin: theme.spacing(2),
    },
    title: {
        lineHeight: '1.15',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    formGroup: {
        margin: theme.spacing(2),
    },
    smallFormControl: {
        margin: theme.spacing(1),
    },
    button: {
        backgroundColor: 'blue',
    },
    link: {
        margin: theme.spacing(7),
        fontSize: '0.8rem',
        color: 'rgba(0, 10, 80, 0.8)',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    box: {
        margin: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    successLink: {
        margin: theme.spacing(1),
        fontSize: '1rem',
        color: 'rgba(0, 0, 0, 1)',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    success: {
        lineHeight: '1.15',
        fontSize: '1rem',
    },
}));

const defaultCredentials = {
    email: '',
    code: '',
    password: '',
    confirmPassword: '',
};

const ForgotPasswordForm = () => {
    const classes = useStyles();

    const [credentials, setCredentials] = useState(defaultCredentials);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const { push } = useHistory();

    const onCompletedSubmitSetPassword = useCallback(
        (data) => {
            try {
                if (data.setPassword) {
                    setSuccess(
                        'Successfully set password. Login with new credentials via the login page:'
                    );
                } else {
                    throw new Error();
                }
            } catch {
                setError('Something went wrong.');
            }
        },
        [setSuccess, setError]
    );

    const onErrorSubmitSetPassword = useCallback(
        (error) => {
            setError(error.message);
        },
        [setError]
    );

    const [submitSetPassword, { loading: submittingSetPassword }] = useMutation(
        SUBMIT_SET_PASSWORD,
        {
            onCompleted: onCompletedSubmitSetPassword,
            onError: onErrorSubmitSetPassword,
        }
    );

    const _handleUpdate = useCallback(
        (field, value) => {
            setError('');
            setCredentials((currentCredentials) => {
                return {
                    ...currentCredentials,
                    [field]: value,
                };
            });
        },
        [setCredentials]
    );

    const _handleSubmit = useCallback(async () => {
        await submitSetPassword({
            variables: {
                ...credentials,
            },
        });
    }, [credentials, submitSetPassword]);

    return (
        <Container className={classes.container}>
            <Box component="img" src="/logo.jpg" className={classes.logo} />
            <Typography className={classes.title}>
                QuoteCheck Forgot Password
            </Typography>
            {success && (
                <Box className={classes.box}>
                    <Typography className={classes.success}>
                        {success}
                    </Typography>
                    <Link
                        className={classes.successLink}
                        onClick={() => {
                            push('/login');
                        }}
                    >
                        Login
                    </Link>
                </Box>
            )}
            {!success && (
                <Box className={classes.box}>
                    <FormGroup className={classes.formGroup}>
                        <FormControl className={classes.smallFormControl}>
                            <TextField
                                label="Email"
                                type="email"
                                variant="outlined"
                                size="small"
                                value={credentials.email || ''}
                                onChange={({ target: { value } }) => {
                                    _handleUpdate('email', value);
                                }}
                                disabled={submittingSetPassword}
                            />
                        </FormControl>
                        <FormControl className={classes.smallFormControl}>
                            <TextField
                                label="One Time Code"
                                variant="outlined"
                                size="small"
                                value={credentials.code || ''}
                                onChange={({ target: { value } }) => {
                                    _handleUpdate('code', value);
                                }}
                                disabled={submittingSetPassword}
                            />
                        </FormControl>
                        <FormControl className={classes.smallFormControl}>
                            <TextField
                                label="New Password"
                                variant="outlined"
                                size="small"
                                type="password"
                                value={credentials.password || ''}
                                onChange={({ target: { value } }) => {
                                    _handleUpdate('password', value);
                                }}
                                disabled={submittingSetPassword}
                            />
                        </FormControl>
                        <FormControl className={classes.smallFormControl}>
                            <TextField
                                label="Confirm Password"
                                variant="outlined"
                                size="small"
                                type="password"
                                value={credentials.confirmPassword || ''}
                                onChange={({ target: { value } }) => {
                                    _handleUpdate('confirmPassword', value);
                                }}
                                disabled={submittingSetPassword}
                            />
                        </FormControl>
                    </FormGroup>
                    {error && <Typography>{error}</Typography>}
                    <Button
                        variant="contained"
                        disableElevation={true}
                        onClick={_handleSubmit}
                        color="primary"
                        disabled={submittingSetPassword}
                    >
                        Submit
                    </Button>
                    <Link
                        className={classes.link}
                        onClick={() => {
                            push('/login');
                        }}
                    >
                        Login?
                    </Link>
                </Box>
            )}
        </Container>
    );
};

export default ForgotPasswordForm;
