import { calculateTotalDollars } from '../../../utils/index';

const updateQuoteFields = ({ currentQuote, key, value }) => {
    const updatedQuote = {
        ...currentQuote,
        [key]: value,
    };
    return updatedQuote;
};

const updateAddressFields = ({ currentQuote, key, value }) => {
    const addressComponent = currentQuote.address_component;
    const updatedAddressComponent = {
        ...addressComponent,
        [key]: value,
    };
    const updatedQuote = {
        ...currentQuote,
        address_component: updatedAddressComponent,
    };
    return updatedQuote;
};

const removeLocationEntryFields = ({ currentQuote, entryId }) => {
    const updatedLocationsRemovedEntry = currentQuote.locations.map(
        (location) => {
            const entries = location.entries.map((entry) => {
                if (entry.id === entryId) {
                    return {
                        ...entry,
                        deleted_at: new Date().toISOString(),
                    };
                }
                return entry;
            });
            return {
                ...location,
                entries,
            };
        }
    );
    const updatedQuote = {
        ...currentQuote,
        locations: updatedLocationsRemovedEntry,
    };
    return updatedQuote;
};

const updateEntryFields = ({ currentQuote, key, value, entryId }) => {
    const updatedLocationsEntry = currentQuote.locations.map((location) => {
        const entries = location.entries.map((entry) => {
            if (entry.id === entryId) {
                const updatedEntry = {
                    ...entry,
                    [key]: value,
                };
                return updatedEntry;
            }
            return entry;
        });
        return {
            ...location,
            entries,
        };
    });
    const updatedQuote = {
        ...currentQuote,
        locations: updatedLocationsEntry,
    };

    return updatedQuote;
};

const updateAnalysedEntryFields = ({ currentQuote, key, value }) => {
    const updatedLocations = currentQuote.locations.map((location) => {
        const entries = location.entries.map((entry) => {
            if (entry.id === key) {
                const analysed_entry = {
                    ...entry.analysed_entry,
                    rate: value,
                    rate_id: value ? value.id : null,
                    total_dollars: calculateTotalDollars(
                        value ? value.rate_dollars : 0,
                        entry.quantity
                    ),
                    is_saved: false,
                };
                return {
                    ...entry,
                    analysed_entry,
                };
            }
            return entry;
        });
        return {
            ...location,
            entries,
        };
    });
    const updatedQuote = {
        ...currentQuote,
        locations: updatedLocations,
    };
    return updatedQuote;
};

export {
    updateQuoteFields,
    updateAddressFields,
    removeLocationEntryFields,
    updateEntryFields,
    updateAnalysedEntryFields,
};
