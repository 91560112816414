import React, { useCallback, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import short from 'short-uuid';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';

import { Container, Button, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import QuoteDetails from './QuoteDetails';
import Upload from './Upload';
import Room from './Room';

const GET_OPTIONS = gql`
    query getOptions {
        trades {
            value: id
            label: name
        }
        uoms {
            value: id
            label: name
        }
        formats {
            value: id
            label: name
        }
        companies {
            value: id
            label: name
        }
    }
`;

const SUBMIT_MANUAL_QUOTE = gql`
    mutation SubmitManualQuote($manual_quote_input: NewQuoteInput!) {
        submitManualQuote(manual_quote_input: $manual_quote_input)
    }
`;

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const defaultQuote = {
    quoted_by_id: null,
    quoted_at: dayjs(new Date()).format('DD/MM/YYYY'),
    quote_ref_id: null,
    claim_type: null,
    company_id: null,
    margin: null,
    format_id: null,
    address_component: {
        unit_number: null,
        street_number: null,
        street: null,
        suburb: null,
        postcode: null,
        state: null,
    },
};

const defaultRoom = {
    location_number: 0,
    depth: null,
    width: null,
    height: null,
    area: null,
    entries: [],
    total: null,
};

const Quote = ({ props }) => {
    const [status, setStatus] = useState('');
    const [error, setError] = useState(null);
    const [quote, setQuote] = useState(defaultQuote);
    const [rooms, setRooms] = useState([]);
    const [tradeOptions, setTradeOptions] = useState([]);
    const [uomOptions, setUomOptions] = useState([]);
    const [formatOptions, setFormatOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [fileInput, setFileInput] = useState({});

    const classes = useStyles();

    const onCompletedGetOptions = useCallback(
        (data) => {
            if (data) {
                const {
                    trades: tradeOptionResults = [],
                    uoms: uomOptionsResults = [],
                    formats: formatOptionsResults = [],
                    companies: companyOptionsResults = [],
                } = data || {};
                setTradeOptions(tradeOptionResults);
                setUomOptions(uomOptionsResults);
                setFormatOptions(formatOptionsResults);
                setCompanyOptions(companyOptionsResults);
            }
        },
        [setTradeOptions, setUomOptions, setFormatOptions, setCompanyOptions]
    );

    useQuery(GET_OPTIONS, { onCompleted: onCompletedGetOptions });

    const _handleUpdateQuote = useCallback(
        (key, value) => {
            setQuote((currentQuote) => ({
                ...currentQuote,
                [key]: value,
            }));
        },
        [setQuote]
    );

    const _handleUpdateAddress = useCallback(
        (key, value) => {
            const newAddress = {
                ...quote.address_component,
                [key]: value,
            };

            _handleUpdateQuote('address_component', newAddress);
        },
        [_handleUpdateQuote, quote]
    );

    const _handleAddRoom = () => {
        const newRoom = {
            ...defaultRoom,
            id: short.generate(),
            location_number: rooms.length,
        };

        setRooms((prevRooms) => [...prevRooms, newRoom]);
    };

    const _handleRemoveRoom = (roomId) => {
        const filteredRooms = rooms.filter((room) => {
            if (room.id === roomId) return false;
            return true;
        });

        let count = 0;
        const updatedRooms = filteredRooms.map((room) => {
            const updatedRoom = {
                ...room,
                location_number: count,
            };

            count++;

            return updatedRoom;
        });
        setRooms(updatedRooms);
    };

    const _handleUpdateRoom = (roomId, key, value) => {
        const updatedRooms = rooms.map((room) => {
            if (room.id !== roomId) return room;

            return {
                ...room,
                [key]: value,
            };
        });

        setRooms(updatedRooms);
    };

    const onCompleteSubmission = () => {
        setStatus('success');
    };

    const onErrorSubmission = (submissionError) => {
        setStatus('error');
        if (submissionError && submissionError.message) {
            setError(submissionError.message);
        }
    };

    const [submitManualQuote] = useMutation(SUBMIT_MANUAL_QUOTE, {
        onCompleted: onCompleteSubmission,
        onError: onErrorSubmission,
    });

    const generateEntryVariables = useCallback((entries) => {
        return entries.map((entry) => {
            const {
                trade_id,
                trade,
                entry_number,
                description,
                quantity,
                rate,
                total,
                uom_id,
                uom,
            } = entry;

            return {
                trade_id,
                trade,
                entry_number,
                description,
                quantity,
                rate,
                total,
                uom_id,
                uom,
            };
        });
    }, []);

    const generateLocationVariables = useCallback(() => {
        return rooms.map((room) => {
            const {
                name,
                location_number,
                depth,
                width,
                height,
                area,
                entries,
                total,
            } = room;

            return {
                name,
                location_number,
                depth,
                width,
                height,
                area,
                entries: generateEntryVariables(entries),
                total,
            };
        });
    }, [generateEntryVariables, rooms]);

    const generateManualQuoteVariables = useCallback(() => {
        const {
            address_component,
            quoted_by_id,
            quoted_at,
            quote_ref_id,
            claim_type,
            company_id,
            margin,
            format_id,
        } = quote;

        const locations = generateLocationVariables();

        return {
            manual_quote_input: {
                file_input: fileInput,
                locations,
                address_component,
                quoted_by_id,
                quoted_at,
                quote_ref_id,
                claim_type,
                company_id,
                margin,
                format_id,
            },
        };
    }, [generateLocationVariables, fileInput, quote]);

    const _handleSubmit = useCallback(async () => {
        setError(null);
        setStatus('submitting');
        const variables = generateManualQuoteVariables();
        await submitManualQuote({
            variables,
        });
    }, [submitManualQuote, generateManualQuoteVariables]);

    const handleFileInput = useCallback(
        (fileName, bucket, key) => {
            const updateFileInput = {
                file_name: fileName,
                bucket,
                key,
            };
            setFileInput(updateFileInput);
        },
        [setFileInput]
    );

    return (
        <Container className={classes.container}>
            <Typography>Enter New Quote</Typography>
            <Upload handleFileInput={handleFileInput} edit />
            <QuoteDetails
                edit
                handleFileInput={handleFileInput}
                updateQuote={_handleUpdateQuote}
                quote={quote}
                updateAddress={_handleUpdateAddress}
                formatOptions={formatOptions}
                companyOptions={companyOptions}
            />
            <Container>
                {rooms.map((room) => {
                    return (
                        <Room
                            room={room}
                            removeRoom={_handleRemoveRoom}
                            updateRoom={_handleUpdateRoom}
                            tradeOptions={tradeOptions}
                            uomOptions={uomOptions}
                        />
                    );
                })}
            </Container>
            <Button
                variant="contained"
                disableElevation={true}
                className={classes.button}
                startIcon={<Add />}
                onClick={_handleAddRoom}
            >
                Room
            </Button>
            <Button
                variant="contained"
                disableElevation={true}
                color="primary"
                className={classes.button}
                onClick={_handleSubmit}
                disabled={status === 'submitting'}
            >
                Submit
            </Button>
            {status === 'submitting' && (
                <Typography>Submitting quote</Typography>
            )}
            {status === 'success' && (
                <Typography>
                    Success - please go to Quote page to view Quote
                </Typography>
            )}
            {status === 'error' && (
                <>
                    <Typography>Error - unable to submit quote.</Typography>
                    {error && <Typography>{error}</Typography>}
                </>
            )}
        </Container>
    );
};

export default Quote;
