import { QUOTE_ACTION } from './constants';
import {
    updateAddressFields,
    updateAnalysedEntryFields,
    updateEntryFields,
    updateQuoteFields,
    removeLocationEntryFields,
} from './utils';

export const savedQuoteReducer = (
    quote: any,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case QUOTE_ACTION.SET_QUOTE:
            return action.payload;
        default:
            break;
    }
};

export const quoteReducer = (
    quote: any,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case QUOTE_ACTION.SET_QUOTE:
            return action.payload;
        case QUOTE_ACTION.RESET_QUOTE:
            return action.payload;
        case QUOTE_ACTION.ADD_ENTRY:
            return quote;
        case QUOTE_ACTION.REMOVE_ENTRY:
            return removeLocationEntryFields({
                currentQuote: quote,
                entryId: action.payload.entryId,
            });
        case QUOTE_ACTION.UPDATE_QUOTE:
            return updateQuoteFields({
                currentQuote: quote,
                key: action.payload.key,
                value: action.payload.value,
            });
        case QUOTE_ACTION.UPDATE_ADDRESS:
            return updateAddressFields({
                currentQuote: quote,
                key: action.payload.key,
                value: action.payload.value,
            });
        case QUOTE_ACTION.UPDATE_CLAIM_INFO:
        case QUOTE_ACTION.UPDATE_INFO:
            return updateQuoteFields({
                currentQuote: quote,
                key: action.payload.key,
                value: action.payload.value,
            });
        case QUOTE_ACTION.UPDATE_ENTRY:
            return updateEntryFields({
                currentQuote: quote,
                entryId: action.payload.entryId,
                key: action.payload.key,
                value: action.payload.value,
            });
        case QUOTE_ACTION.UPDATE_RATE:
            return updateAnalysedEntryFields({
                currentQuote: quote,
                key: action.payload.key,
                value: action.payload.value,
            });
        default:
            return quote;
    }
};
