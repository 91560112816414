import { useCallback, useContext } from 'react';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import { QUOTE_ACTION } from '../constants';
import { QuoteDispatchContext } from '../context';

const Entry = ({ entry }: { entry: any }) => {
    const dispatch: any = useContext(QuoteDispatchContext);

    const updateEntry = useCallback(
        ({ key, value }: { key: string; value: any }) => {
            dispatch({
                type: QUOTE_ACTION.UPDATE_ENTRY,
                payload: {
                    key,
                    value,
                    entryId: entry.id,
                },
            });
        },
        [entry, dispatch]
    );

    return (
        <TableRow>
            <TableCell size="small" style={{ width: '5%' }}>
                <Checkbox
                    checked={entry.isSelected ?? false}
                    onChange={() =>
                        updateEntry({
                            key: 'isSelected',
                            value: !entry.isSelected,
                        })
                    }
                />
            </TableCell>
            <TableCell style={{ width: '10%' }}>{entry.trade}</TableCell>
            <TableCell style={{ width: '30%' }}>{entry.description}</TableCell>
            <TableCell align="right" style={{ width: '10%' }}>
                {entry.quantity}
            </TableCell>
            <TableCell align="right" style={{ width: '10%' }}>
                {entry.uom}
            </TableCell>
            <TableCell align="right" style={{ width: '10%' }}>
                {entry.rate_dollars}
            </TableCell>
            <TableCell align="right" style={{ width: '10%' }}>
                {entry.total_dollars}
            </TableCell>
            <TableCell align="right" style={{ width: '10%' }}>
                {entry.contract_ref}
            </TableCell>
        </TableRow>
    );
};

export default Entry;
