import React, { useState, useCallback, Fragment } from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import { Container, TablePagination } from '@material-ui/core';

import Quotes from '../../components/Quotes';

const GET_PAGINATED_QUOTES = gql`
    query paginatedQuotes($page: Int, $per_page: Int) {
        paginated_quotes(page: $page, per_page: $per_page) {
            quotes {
                id
                file {
                    name
                }
                company {
                    name
                }
                format {
                    name
                }
                created_at
                status
            }
            total
            page
            pages
            per_page
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const Home = () => {
    const [quotes, setQuotes] = useState([]);
    const [quotesCount, setQuotesCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPages] = useState(10);
    const classes = useStyles();

    const onCompletedGetQuotes = useCallback(
        (data) => {
            if (data) {
                const { paginated_quotes = {} } = data;
                const { quotes = [], total = 0 } = paginated_quotes;
                setQuotes(quotes);
                setQuotesCount(total);
            }
        },
        [setQuotes]
    );

    const { refetch: refetchPaginatedQuotes, loading: loadingQuotes } =
        useQuery(GET_PAGINATED_QUOTES, {
            variables: {
                page: page + 1, // material ui pages start from 0, python backend starts at page 1
                per_page: rowsPerPage,
            },
            onCompleted: onCompletedGetQuotes,
        });

    const onRowsPerPageChange = useCallback(
        ({ target }) => {
            const updatedRowsPerPage = target.value;

            setRowsPerPages(updatedRowsPerPage);

            const variables = {
                page: page + 1, // material ui pages start from 0, python backend starts at page 1
                per_page: updatedRowsPerPage,
            };

            refetchPaginatedQuotes(variables);
        },
        [setRowsPerPages, refetchPaginatedQuotes, page]
    );

    const onPageChange = useCallback(
        (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
            const updatedPage = page;

            setPage(updatedPage);

            const variables = {
                page: updatedPage + 1, // material ui pages start from 0, python backend starts at page 1
                per_page: rowsPerPage,
            };

            refetchPaginatedQuotes(variables);
        },
        [setPage, refetchPaginatedQuotes, rowsPerPage]
    );

    return (
        <Fragment>
            <Container className={classes.container}>
                <Quotes quotes={quotes} loading={loadingQuotes} />
                <TablePagination
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={quotesCount}
                    onRowsPerPageChange={onRowsPerPageChange}
                    onPageChange={onPageChange}
                />
            </Container>
        </Fragment>
    );
};

export default Home;
