import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { useContext } from 'react';
import { QuoteContext } from '../context';

const Margin = () => {
    const quote: any = useContext(QuoteContext);

    return (
        <TableContainer
            component={Paper}
            style={{ maxHeight: '100%', overflowY: 'scroll' }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell
                            size="small"
                            style={{
                                fontWeight: 'bold',
                                width: '5%',
                            }}
                        >
                            SELECT
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold', width: '10%' }}>
                            MARGIN
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', width: '10%' }}
                        >
                            PERCENTAGE
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', width: '80%' }}
                        ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell size="small" style={{ width: '5%' }}>
                            <Checkbox checked />
                        </TableCell>
                        <TableCell style={{ width: '10%' }}>Margin</TableCell>
                        <TableCell align="right" style={{ width: '10%' }}>
                            {quote.margin || 'NA'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Margin;
