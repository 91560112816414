import { gql } from '@apollo/client';

const GET_QUOTE = gql`
    query ($id: Int!) {
        quote(id: $id) {
            id
            quoted_by_id
            quoted_at
            quote_ref_id
            claim_number
            claim_type
            company_id
            margin
            status
            created_at
            address_component {
                unit_number
                street_number
                street
                suburb
                state
                postcode
            }
            format_id
            format {
                name
            }
            locations {
                id
                name
                total_dollars
                analysed_total_dollars
                width
                height
                depth
                entries {
                    id
                    entry_number
                    page_number
                    deleted_at
                    description
                    description_highlight_areas {
                        height
                        top
                        left
                        width
                        pageIndex
                    }
                    comment
                    uom
                    uom_highlight_areas {
                        height
                        top
                        left
                        width
                        pageIndex
                    }
                    trade
                    trade_highlight_areas {
                        height
                        top
                        left
                        width
                        pageIndex
                    }
                    trade_id
                    quantity
                    quantity_highlight_areas {
                        height
                        top
                        left
                        width
                        pageIndex
                    }
                    rate_dollars
                    rate_highlight_areas {
                        height
                        top
                        left
                        width
                        pageIndex
                    }
                    total_dollars
                    total_highlight_areas {
                        height
                        top
                        left
                        width
                        pageIndex
                    }
                    contract_ref
                    contract_highlight_areas {
                        height
                        top
                        left
                        width
                        pageIndex
                    }
                    analysed_entry {
                        id
                        total_dollars
                        rate_id
                        rate {
                            id
                            trade
                            item
                            action
                            uom
                            rate_dollars
                        }
                        tolerance {
                            band
                            colour
                        }
                    }
                }
            }
        }
    }
`;

const ADD_ENTRY = gql`
    mutation AddEntry($quote_id: Int!, $entry_input: EntryInput!) {
        addEntry(quote_id: $quote_id, entry_input: $entry_input)
    }
`;

const EDIT_ENTRIES = gql`
    mutation EditEntries($quote_id: Int!, $entries_input: [EntryInput]!) {
        editEntries(quote_id: $quote_id, entries_input: $entries_input)
    }
`;

export { GET_QUOTE, ADD_ENTRY, EDIT_ENTRIES };
