import { useCallback, useContext } from 'react';

import dayjs from 'dayjs';
import {
    Typography,
    TextField,
    makeStyles,
    Box,
    FormControl,
    Divider,
} from '@material-ui/core';
import DateTimeInput from '../../../FormHelpers/Date';
import {
    EditContext,
    QuoteContext,
    QuoteDispatchContext,
} from '../../../Quote/context';
import { QUOTE_ACTION } from '../../../Quote/constants';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 200,
    },
    smallFormControl: {
        margin: theme.spacing(2),
        width: 140,
    },
    largeFormControl: {
        margin: theme.spacing(2),
        width: 335,
    },
    divider: {
        width: 200,
    },
}));

const ClaimInfo = () => {
    const edit = useContext(EditContext);
    const quote = useContext(QuoteContext);
    const dispatch = useContext(QuoteDispatchContext);
    const classes = useStyles();

    const updateInfo = useCallback(
        (key, value) => {
            dispatch({
                type: QUOTE_ACTION.UPDATE_CLAIM_INFO,
                payload: {
                    key,
                    value,
                },
            });
        },
        [dispatch]
    );

    return (
        <Box style={{ width: '100%' }}>
            <Typography>Claim Information</Typography>
            <Divider className={classes.divider} />

            <Box display="flex" flexWrap="wrap" gap="16px">
                <FormControl className={classes.smallFormControl}>
                    <DateTimeInput
                        selectedDate={
                            quote.quoted_at
                                ? dayjs(quote.quoted_at, 'DD/MM/YYYY')
                                : null
                        }
                        handleDateChange={(value) => {
                            const date = dayjs(value).format('DD/MM/YYYY');
                            updateInfo('quoted_at', date);
                        }}
                        label="Claim Date"
                        inputVariant="outlined"
                        size="small"
                        format="DD/MM/YYYY"
                        disabled={!edit}
                    />
                </FormControl>
                <FormControl className={classes.smallFormControl}>
                    <TextField
                        label="Claim #"
                        variant="outlined"
                        size="small"
                        value={quote.claim_number || ''}
                        onChange={({ target: { value } }) => {
                            updateInfo('claim_number', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
                <FormControl className={classes.smallFormControl}>
                    <TextField
                        label="Quote/Job #"
                        variant="outlined"
                        size="small"
                        value={quote.quote_ref_id || ''}
                        onChange={({ target: { value } }) => {
                            updateInfo('quote_ref_id', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
                <FormControl className={classes.largeFormControl}>
                    <TextField
                        label="Claim Type"
                        variant="outlined"
                        size="small"
                        value={quote.claim_type || ''}
                        onChange={({ target: { value } }) => {
                            updateInfo('claim_type', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
                <FormControl className={classes.largeFormControl}>
                    <TextField
                        label="Estimator/Builder"
                        variant="outlined"
                        size="small"
                        value={quote.builder?.name || ''}
                        disabled
                        // Commented out as part of FEAT005
                        // onChange={({ target: { value } }) => {
                        //     updateInfo('quoted_by', value);
                        // }}
                        // disabled={!edit}
                    />
                </FormControl>
            </Box>
        </Box>
    );
};

export default ClaimInfo;
