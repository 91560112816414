import { TableHead, TableRow, TableCell } from '@material-ui/core';

const Header = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>View</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default Header;
