import React from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
} from '@material-ui/core';
import Entry from '../Entry';

const Location = ({ location }: { location: any }) => {
    return (
        <TableContainer
            component={Paper}
            style={{ maxHeight: '100%', overflowY: 'auto' }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell
                            size="small"
                            style={{
                                fontWeight: 'bold',
                                width: '5%',
                            }}
                        >
                            SELECT
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold', width: '10%' }}>
                            TRADE
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold', width: '30%' }}>
                            DESCRIPTION
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', width: '10%' }}
                        >
                            QUANTITY
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', width: '10%' }}
                        >
                            UOM
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', width: '10%' }}
                        >
                            RATE
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', width: '10%' }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell
                            align="right"
                            style={{ fontWeight: 'bold', width: '10%' }}
                        >
                            CONTRACT REF
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {location.entries.map((entry: any) => (
                        <Entry entry={entry} key={`entry-key-${entry.id}`} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Location;
