import { useCallback, useReducer, useState } from 'react';
import { quoteReducer } from './reducer';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

import { GET_QUOTE } from './gql';
import Viewer from './Viewer';
import { QuoteContext, QuoteDispatchContext } from './context';
import { QUOTE_ACTION } from './constants';
import ButtonContainer from './ButtonContainer';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        minWidth: '90vw',
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const QuoteAddFields = () => {
    const params = useParams();
    const { id: quoteId } = params as any;

    const [savedQuote, setSavedQuote] = useState(null);
    const [url, setUrl] = useState('');
    const [showingMargin, setShowingMargin] = useState(false);

    const [quote, dispatch] = useReducer(quoteReducer, null);

    const classes = useStyles();

    const onCompletedGetQuote = useCallback((data: any) => {
        if (data) {
            const { quote } = data;
            setSavedQuote(quote);
            dispatch({
                type: QUOTE_ACTION.SET_QUOTE,
                payload: quote,
            });
        }
    }, []);

    /**********************************************************************
     * GRAPHQL
     **********************************************************************/
    const { refetch, loading: loadingQuote } = useQuery(GET_QUOTE, {
        variables: {
            id: parseInt(quoteId),
        },
        onCompleted: onCompletedGetQuote,
        notifyOnNetworkStatusChange: true,
    });

    const handleFileChange = (newUrl: string) => {
        setUrl(newUrl);
    };

    const toggleMarginView = () => {
        setShowingMargin(!showingMargin);
    };

    if (!quote || loadingQuote) {
        return (
            <Container className={classes.container}>
                <Typography>Loading...</Typography>
            </Container>
        );
    }

    return (
        <QuoteContext.Provider value={quote}>
            <QuoteDispatchContext.Provider value={dispatch as any}>
                <Container className={classes.container}>
                    <Typography>Quote: {quoteId}</Typography>
                    <ButtonContainer
                        refetch={refetch}
                        savedQuote={savedQuote}
                        showingMargin={showingMargin}
                        toggleMarginView={toggleMarginView}
                    />
                    <Viewer
                        url={url}
                        setUrl={handleFileChange}
                        showingMargin={showingMargin}
                    />
                </Container>
            </QuoteDispatchContext.Provider>
        </QuoteContext.Provider>
    );
};

export default QuoteAddFields;
