import { useCallback, useContext } from 'react';

import {
    Typography,
    InputLabel,
    makeStyles,
    Box,
    FormControl,
    Divider,
} from '@material-ui/core';
import Select from '../../../FormHelpers/Select';
import {
    EditContext,
    OptionsContext,
    QuoteContext,
    QuoteDispatchContext,
} from '../../../Quote/context';
import { QUOTE_ACTION } from '../../../Quote/constants';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 200,
    },
    smallFormControl: {
        margin: theme.spacing(2),
        width: 140,
    },
    largeFormControl: {
        margin: theme.spacing(2),
        width: 300,
    },
    divider: {
        width: 200,
    },
}));

const customOptions = [
    { value: 'reviewing', label: 'reviewing' },
    { value: 'reviewed', label: 'reviewed' },
];

const QuoteInfo = () => {
    const edit = useContext(EditContext);
    const quote = useContext(QuoteContext);
    const dispatch = useContext(QuoteDispatchContext);
    const options = useContext(OptionsContext);

    const classes = useStyles();

    const updateInfo = useCallback(
        (key, value) => {
            dispatch({
                type: QUOTE_ACTION.UPDATE_INFO,
                payload: {
                    key,
                    value,
                },
            });
        },
        [dispatch]
    );

    return (
        <Box style={{ width: '100%' }}>
            <Typography>Quote Information</Typography>
            <Divider className={classes.divider} />

            <Box display="flex" flexWrap="wrap" gap="16px">
                <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                >
                    <InputLabel>Company</InputLabel>
                    <Select
                        label="Company"
                        id="company-input"
                        options={options.companies}
                        value={quote.company_id || ''}
                        onChange={({ target: { value } }) => {
                            updateInfo('company_id', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>

                <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                >
                    <InputLabel>Status</InputLabel>
                    <Select
                        label="Status"
                        id="status-input"
                        options={[
                            ...(customOptions.some(
                                (option) => option.value === quote.status
                            )
                                ? []
                                : [
                                      {
                                          value: quote.status,
                                          label: quote.status,
                                      },
                                  ]),
                            ...customOptions,
                        ]}
                        value={quote.status || ''}
                        onChange={({ target: { value } }) => {
                            updateInfo('status', value);
                        }}
                        disabled={!edit}
                    />
                </FormControl>
            </Box>
        </Box>
    );
};

export default QuoteInfo;
