import {
    convertHighlightAreasToSpan,
    convertToCents,
    convertToNumber,
} from '../../utils';

const generateNewEntriesVariables = (quote: any) => {
    let newEntryInput: any = {};
    const existingEntries: any = [];

    quote.locations.forEach((location: any) => {
        existingEntries.push(...location.entries);
    });

    existingEntries.forEach((existingEntry: any) => {
        if (existingEntry.isNew) {
            newEntryInput = generateNewEntryDetails(existingEntry);
        }
    });

    const existingEntriesCount = existingEntries.length - 1;
    const locationId = quote.locations[0].id;

    newEntryInput['entry_number'] = existingEntriesCount;
    newEntryInput['location_id'] = locationId;

    return newEntryInput;
};

const generateEditEntriesVariables = (quote: any) => {
    const entriesInput: any[] = [];
    quote.locations.forEach((location: any) => {
        const entries = generateUpdateEntryDetails(location.entries);
        entriesInput.push(...entries);
    });

    return entriesInput;
};

const generateNewEntryDetails = (newEntry: any) => {
    const newEntryDetails = {
        page_number: extractPageNumberFromHighlightAreas(newEntry),
        trade: newEntry.trade,
        trade_span: convertHighlightAreasToSpan(
            newEntry.trade,
            newEntry.trade_highlight_areas
        ),
        description: newEntry.description,
        description_span: convertHighlightAreasToSpan(
            newEntry.description,
            newEntry.description_highlight_areas
        ),
        comment: newEntry.comment,
        uom: newEntry.uom,
        uom_span: convertHighlightAreasToSpan(
            newEntry.uom,
            newEntry.uom_highlight_areas
        ),
        quantity: convertToNumber(newEntry.quantity),
        quantity_span: convertHighlightAreasToSpan(
            newEntry.quantity,
            newEntry.quantity_highlight_areas
        ),
        rate_cents: convertToCents(newEntry.rate_dollars),
        rate_span: convertHighlightAreasToSpan(
            newEntry.rate_dollars,
            newEntry.rate_highlight_areas
        ),
        total_cents: convertToCents(newEntry.total_dollars),
        total_span: convertHighlightAreasToSpan(
            newEntry.total_dollars,
            newEntry.total_highlight_areas
        ),
        contract_ref: newEntry.contract_ref,
        contract_ref_span: convertHighlightAreasToSpan(
            newEntry.contract_ref,
            newEntry.contract_highlight_areas
        ),
    };

    return newEntryDetails;
};

const generateUpdateEntryDetails = (entries: any) => {
    return entries
        .filter((entry: any) => entry.isEdited)
        .map((entry: any) => {
            const entryDetails = {
                id: entry.id,
                page_number: extractPageNumberFromHighlightAreas(entry),
                trade: entry.trade,
                trade_span: convertHighlightAreasToSpan(
                    entry.trade,
                    entry.trade_highlight_areas
                ),
                description: entry.description,
                description_span: convertHighlightAreasToSpan(
                    entry.description,
                    entry.description_highlight_areas
                ),
                comment: entry.comment,
                uom: entry.uom,
                uom_span: convertHighlightAreasToSpan(
                    entry.uom,
                    entry.uom_highlight_areas
                ),
                quantity: convertToNumber(entry.quantity),
                quantity_span: convertHighlightAreasToSpan(
                    entry.quantity,
                    entry.quantity_highlight_areas
                ),
                rate_cents: convertToCents(entry.rate_dollars),
                rate_span: convertHighlightAreasToSpan(
                    entry.rate_dollars,
                    entry.rate_highlight_areas
                ),
                total_cents: convertToCents(entry.total_dollars),
                total_span: convertHighlightAreasToSpan(
                    entry.total_dollars,
                    entry.total_highlight_areas
                ),
                contract_ref: entry.contract_ref,
                contract_ref_span: convertHighlightAreasToSpan(
                    entry.contract_ref,
                    entry.contract_highlight_areas
                ),
                isEdited: true,
            };

            return entryDetails;
        });
};

const extractPageNumberFromHighlightAreas = (entry: any) => {
    const rateHighlightArea = entry.rate_highlight_areas?.[0];
    if (!!rateHighlightArea) {
        return rateHighlightArea.pageIndex;
    }
    const totalHighlightArea = entry.total_highlight_areas?.[0];
    if (!!totalHighlightArea) {
        return totalHighlightArea.pageIndex;
    }
    return undefined;
};

export { generateEditEntriesVariables, generateNewEntriesVariables };
